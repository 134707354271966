import { Box } from '@material-ui/core'
import Content from './content'

export default function WelfarePolicyContent() {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ marginTop: '0.1rem' }}
      />
      <Content />
    </>
  )
}
