import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  styled,
  Typography,
  Link,
} from '@material-ui/core'

const AccordionContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  padding-left: 4rem;
  padding-right: 4rem;
`

const AccordionTextContainer = styled(Box)`
  margin-top: 2rem;
`
const AccordionLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1652)]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '1.2rem',
      },
      [theme.breakpoints.down(600)]: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: #008174;
  padding-left: 16px;
`

export const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        width: '90%',
        textAlign: 'center',
      },
    }
  }}

  margin-bottom: 1rem;
  margin-top: 1rem;
`
export const ButtonContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center',
      },
    }
  }}
`
export const StyledAccordion = styled(Accordion)`
  &::before {
    position: relative;
  }
  padding: 0;
  margin: 2px !important;
`
export const StyledAccordionSummary = styled(AccordionSummary)``

export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        lineHeight: '1.8',
        marginBottom: '0.3rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  padding-left: 16px;
  line-height: 2.1;
`
export const LastTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        lineHeight: '1.8',
        marginBottom: '2rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  padding-left: 16px;
  line-height: 2.1;
  margin-bottom: 1rem;
`

export default function AccordionFeature() {
  return (
    <AccordionContainer>
      <AccordionTextContainer>
        <AccordionLabelTypography>Welfare policy</AccordionLabelTypography>

        <LastTypography>
          It’s important that Tri Tag Rugby games are played not only within its
          laws, but also in a manner that reflects our ethics, morals and
          beliefs regarding the experience a participant should expect through
          participation in Tri Tag Rugby. The principles of fun, inclusiveness,
          fairness, respect, responsibility and safety should be taken
          seriously. At Tri Tag Rugby we aim to ensure our core values, positive
          behaviours and attitudes are maintained and that everyone involved in
          our sport is aware of his or her legal and ethical rights and
          responsibilities.
        </LastTypography>
        <LastTypography>
          The positive virtues of playing sport are well documented and Tri Tag
          Rugby is a strong advocate of the value of regular exercise and the
          health and fitness benefits derived from playing Tri Tag Rugby.
          Equally important is the enjoyment, education and social interaction
          that the game provides for all involved.
        </LastTypography>

        <LastTypography>
          Tri Tag Rugby is committed to the health, safety and well-being of all
          participants by providing a safe, inclusive and fun environment for
          playing the game of Tri Tag Rugby. To that end, Tri Tag Rugby have
          developed appropriate risk management, participation agreement, codes
          of conduct and emergency response guidelines. Participants are
          required to act in accordance with these guidelines.
        </LastTypography>

        <LastTypography sx={{ marginBottom: '3rem' }}>
          As above, Tri Tag Rugby is committed to maintaining the well-being of
          our players and encourage anyone going through a difficult time to
          speak up. The links below are a list of useful resources to assist
          anyone who may be suffering from anxiety, depression and other
          problems that may affect people’s lives.
        </LastTypography>

        <LastTypography>
          <b>Websites</b>
        </LastTypography>
        <StyledTypography>
          Beyondblue –{' '}
          <Link href="https://www.beyondblue.org.au" target="_blank">
            www.beyondblue.org.au{' '}
          </Link>
          | Information on anxiety and depression, online forums and specific
          advice for men, women, older people, youth, multicultural, Aboriginal
          and Torres Strait Islander people, LGBTI and pregnancy/postnatal
          issues.
        </StyledTypography>
        <StyledTypography>
          Headspace –{' '}
          <Link href="https://www.headspace.org.au" target="_blank">
            www.headspace.org.au{' '}
          </Link>
          | Aimed at younger people – includes information on mental health and
          well-being, alcohol and drugs, work and study. Online resources and
          links to local centres.
        </StyledTypography>
        <StyledTypography>
          Sane –{' '}
          <Link href="https://www.sane.org" target="_blank">
            www.sane.org{' '}
          </Link>
          | information on mental health, assistance for families and carers,
          links to getting help and online forums.
        </StyledTypography>
        <StyledTypography>
          The Line –{' '}
          <Link href="https://www.theline.org.au" target="_blank">
            www.theline.org.au{' '}
          </Link>
          | Information on domestic violence and how to get help.
        </StyledTypography>
        <StyledTypography>
          Your Room –{' '}
          <Link href="https://www.yourroom.com.au" target="_blank">
            www.yourroom.com.au{' '}
          </Link>
          | Information on drug and alcohol services, where to get help and how
          to help family members
        </StyledTypography>
        <StyledTypography>
          Gambler’s help –{' '}
          <Link href="https://www.gamblershelp.com.au" target="_blank">
            www.gamblershelp.com.au{' '}
          </Link>
          | For assistance with problem gambling, youth services and assistance
          for family members.
        </StyledTypography>
        <LastTypography>
          1800RETROSPECT – This is a 24-hour national sexual assault, family and
          domestic violence counselling line for any Australian who has
          experienced, or is at risk of, family and domestic violence and/or
          sexual assault. Individuals can also access local support services and
          search the internet using Daisy, a free app developed by 1800RESPECT
          that protects user privacy.
        </LastTypography>
        <LastTypography>
          <b>Emergency</b>
        </LastTypography>
        <StyledTypography>Lifeline: 13 11 14</StyledTypography>
        <StyledTypography>Suicideline: 1300 651 251</StyledTypography>
        <StyledTypography>Mensline: 1300 789 978 </StyledTypography>
        <StyledTypography>Beyond Blue: 1300 224 636 </StyledTypography>
        <StyledTypography>Kid’s Helpline: 1800 551 800</StyledTypography>
      </AccordionTextContainer>
    </AccordionContainer>
  )
}
